<template>
  <!--begin::Basic info-->
  <div class="card mb-5 mb-xl-10">
    <!--begin::Card header-->
    <div class="card-header border-0 bg-default">
      <!--begin::Card title-->
      <div class="card-title m-0 d-flex flex-column w-100">
        <!--begin::Navs-->
        <div
          class="
            d-flex
            align-items-center
            justify-content-start
            cursor-pointer
            mb-3
            mt-7
          "
          @click="backMenu"
        >
          <i class="bi bi-arrow-left h3 me-4 back-menu text-white"></i>
          <h3 class="fw-bolder m-0 back-menu text-white">
            Hasil Komparasi Peta Suara ({{ dataYear }} -
            {{ parseInt(dataYear) + 5 }})
          </h3>
        </div>
        <div class="d-flex overflow-auto h-55px mb-5">
          <ul
            class="
              nav nav-stretch nav-line-tabs nav-line-tabs-2x
              border-transparent
              fs-5
              fw-bolder
              flex-nowrap
            "
          >
            <!--begin::Nav item-->
            <li class="nav-item">
              <router-link
                :to="{ name: 'comparison.map.detail.pileg' }"
                class="nav-link text-active-white text-warning me-6"
                active-class="active"
              >
                Pileg
              </router-link>
            </li>
            <!--end::Nav item-->
            <!--begin::Nav item-->
            <li class="nav-item d-none">
              <router-link
                class="nav-link text-active-white text-warning me-6"
                to="/comparison-map/detail/pilkada"
                active-class="active"
              >
                Pilkada
              </router-link>
            </li>
            <!--end::Nav item-->
          </ul>
        </div>
        <!--begin::Navs-->
      </div>
      <!--end::Card title-->
    </div>
    <!--begin::Card header-->

    <!--begin::Card body-->
    <div class="card-body border-top p-9">
      <router-view />
    </div>
    <!--end::Card body-->
  </div>
  <!--end::Basic info-->
</template>

<script lang="ts">
import { setCurrentPageTitle } from "@/core/helpers/breadcrumb";
import { defineComponent } from "vue";
import { useRouter } from "vue-router";
import { useStore } from "vuex";
import CryptoJS from "crypto-js";

export default defineComponent({
  name: "MapComparison-Detail",
  props: ["id", "year"],
  setup(props) {
    setCurrentPageTitle("Komparasi Peta Suara");
    const store = useStore();
    const router = useRouter();

    store.commit("SET_ACTIVE_MENU", "map-comparison");

    const dataId = CryptoJS.AES.decrypt(props.id, "PKS.id").toString(
      CryptoJS.enc.Utf8
    );
    const dataYear = CryptoJS.AES.decrypt(props.year, "PKS.id").toString(
      CryptoJS.enc.Utf8
    );
    if (!dataId && !dataYear) {
      router.push({ name: "comparison.map" });
    }

    const backMenu = () => {
      router.push({ name: "comparison.map" });
    };

    return {
      backMenu,
      dataId,
      dataYear,
    };
  },
});
</script>
